<template>
  <!-- TODO O TEMPLATE FEITO UTILIZANDO O BOOTSTRAP VUE -->
  <div class="productslist">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <!-- BARRA DE PESQUISA -->
    <h2>Descontos</h2>
    <br />
    <!-- BARRA DE PESQUISA - PESQUISAR POR ENCOMENDA -->
    <!-- PAGINAÇÃO NA PARTE SUPERIOR DA TABELA -->
    <b-button class="waitAddress" v-b-modal.add-disc>Adicionar Desconto</b-button>
    <!-- TABELA COM TODOS OS PRODUTOS -->
    <div class="table">
      <b-table striped hover :items="discounts" :fields="fields" :filter="filter" :per-page="perPage" :current-page="currentPage" thead-class="text-white" small>
        <template #cell(id)="data">
          {{ data.item.id }}
        </template>
        <template #cell(active)="data">
          <span v-if="data.item.active" class="dot-green"></span>
          <span v-else class="dot-red"></span>
        </template>
        <template #cell(brand)="data">
          <span v-for="(brand, index) in data.item.brands.slice(0, 5)" :key="index">
            {{ brand.name }}
            <span v-if="index !== 4 && index !== data.item.brands.slice(0, 5).length - 1">, </span>
            <span v-if="index === 4 && data.item.brands.length > 5">, ...</span>
          </span>
        </template>
        <template #cell(start_date)="data">
          {{ splitDate(data.item.start_date) }}
        </template>
        <template #cell(end_date)="data">
          {{ splitDate(data.item.end_date) }}
        </template>
        <template #cell(button)="data">
          <a id="updateValues" @click="filterProducts(data.item)">🔧</a>
          <br />
          <a id="updateValues" @click="passDiscModal(data.item)">🔄</a>
          <a id="updateValues" @click="deleteDiscount(data.item)">❌</a>
        </template>
      </b-table>
    </div>
    <!-- PAGINAÇÃO NO INFERIO DA TABELA -->
    <b-modal id="add-disc" title="Adicionar Desconto!" ref="modal_add-disc" hide-footer>
      <form v-on:submit.prevent="createDiscount()">
        <div class="form-group">
          <label for="new_brand">Marca</label>
          <v-select :options="brands" v-model="new_brand" label="name" multiple>
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_start_date">Data Inicial</label>
          <input type="date" class="form-control" id="new_start_date" v-model="new_start_date" required />
          <label for="new_end_date">Data Final</label>
          <input type="date" class="form-control" id="new_end_date" v-model="new_end_date" required />
          <label for="new_margin">Margem</label>
          <input type="number" min="0" step="any" class="form-control" id="new_margin" v-model="new_margin" required />
          <label for="new_free_shipping">Free Shipping</label>
          <input type="number" min="0" step="any" class="form-control" id="new_free_shipping" v-model="new_free_shipping" required />
          <label for="new_payment_perc">% Pagamento</label>
          <input type="number" min="0" step="any" class="form-control" id="new_payment_perc" v-model="new_payment_perc" required />
          <label for="sknew_kg_priceu">Preço Kg</label>
          <input type="number" min="0" step="any" class="form-control" id="new_kg_price" v-model="new_kg_price" required />
          <label for="new_limit_discount">Desconto Limite</label>
          <input type="number" min="0" step="any" class="form-control" id="new_limit_discount" v-model="new_limit_discount" required />
          <label for="new_site">Site</label>
          <v-select :options="sites" v-model="new_site" label="name">
            <template slot="option" slot-scope="option"> {{ option.name }}</template>
          </v-select>
          <label for="new_priority">Prioridade</label>
          <input type="number" min="0" step="any" class="form-control" id="new_priority" v-model="new_priority" required />
        </div>
        <button type="submit" class="btn btn-primary">Adicionar</button>
      </form>
    </b-modal>
    <b-modal id="update-disc" title="Atualizar Desconto!" ref="modal_update-disc" hide-footer>
      <form v-on:submit.prevent="updateDiscount()">
        <div class="form-group">
          <label for="start_date">Data Inicial</label>
          <input type="date" class="form-control" id="start_date_up" v-model="start_date_up" required />
          <label for="end_date">Data Final</label>
          <input type="date" class="form-control" id="end_date_up" v-model="end_date_up" required />
          <label for="margin">Margem</label>
          <input type="number" min="0" step="any" class="form-control" id="margin_up" v-model="margin_up" required />
          <label for="free_shipping">Free Shipping</label>
          <input type="number" min="0" step="any" class="form-control" id="free_shipping_up" v-model="free_shipping_up" required />
          <label for="payment_perc">% Pagamento</label>
          <input type="number" min="0" step="any" class="form-control" id="payment_perc_up" v-model="payment_perc_up" required />
          <label for="kg_price">Preço Kg</label>
          <input type="number" min="0" step="any" class="form-control" id="kg_price_up" v-model="kg_price_up" required />
          <label for="limit_discount">Desconto Limite</label>
          <input type="number" min="0" step="any" class="form-control" id="limit_discount_up" v-model="limit_discount_up" required />
          <label for="priority">Prioridade</label>
          <input type="number" min="0" step="any" class="form-control" id="priority_up" v-model="priority_up" required />
        </div>
        <button type="submit" class="btn btn-primary">Atualizar</button>
      </form>
    </b-modal>
    <b-modal id="add-prods" size="xl" title="Atualizar Produtos Desconto!" ref="modal_add-prod" hide-footer>
      <form v-on:submit.prevent="updateProducts()">
        <div class="row">
          <div class="col-md-5">
            <select size="13" class="form-control" id="list1" multiple v-model="select">
              <option style="font-size: small" v-for="item1 in list1" :key="item1.id" :value="item1">{{ item1.name }} -> SKU: {{ item1.sku[0] }} || Stock: {{ item1.stock }} || Stock Auto: {{ item1.stock_virtual }}</option>
            </select>
          </div>
          <div class="col-sm-2" id="buttons">
            <button id="arrow" type="button" class="btn btn-primary btn-lg btn-block" @click="allToRight">&raquo;</button>
            <button id="arrow" type="button" class="btn btn-primary btn-lg btn-block" @click="oneToRight">&rsaquo;</button>
            <button id="arrow" type="button" class="btn btn-primary btn-lg btn-block" @click="oneToLeft">&lsaquo;</button>
            <button id="arrow" type="button" class="btn btn-primary btn-lg btn-block" @click="allToLeft">&laquo;</button>
          </div>
          <div class="col-md-5">
            <select size="13" class="form-control" id="list2" multiple v-model="select2">
              <option style="font-size: small" v-for="item2 in list2" :key="item2.id" :value="item2">{{ item2.name }} -> SKU: {{ item2.sku[0] }} || Stock: {{ item2.stock }} || Stock Auto: {{ item2.stock_virtual }}</option>
            </select>
          </div>
        </div>
        <button id="" type="submit" class="btn btn-primary">Atualizar</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      perPage: 100,
      currentPage: 1,
      filter: null,
      criteria: null,
      discounts: [],
      brands: [],
      rows: 1,
      sites: ['idivia', 'kiooli'],
      fields: [
        {
          key: 'id',
          label: 'ID',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'active',
          label: 'Estado',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'brand',
          label: 'Marca',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'start_date',
          label: 'Data Inicial',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
        },
        {
          key: 'end_date',
          label: 'Data Final',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'margin',
          label: 'Margem',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'free_shipping',
          label: 'Free Shipping',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'payment_perc',
          label: '% Pagamento',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'kg_price',
          label: 'Preço Kg',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'limit_discount',
          label: 'Desconto Limite',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'site',
          label: 'Site',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'priority',
          label: 'Prioridade',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
          sortable: true,
        },
        {
          key: 'button',
          label: '',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle',
        },
      ],

      //Modal Add
      new_brand: [],
      new_start_date: '',
      new_end_date: '',
      new_margin: '',
      new_free_shipping: '',
      new_payment_perc: '',
      new_kg_price: '',
      new_limit_discount: '',
      new_site: '',
      new_priority: '',

      //Modal Update
      id_up: '',
      start_date_up: '',
      end_date_up: '',
      margin_up: '',
      free_shipping_up: '',
      payment_perc_up: '',
      kg_price_up: '',
      limit_discount_up: '',
      priority_up: '',

      //Modal Add Products
      select: [],
      select2: [],
      list1: [],
      list2: [],
      products: [],
      products_discount: [],
      products_brand: [],
    };
  },
  methods: {
    /* FUNÇÃO ASSINCRONA QUE VAI À STORE BUSCAR OS PRODUTOS */
    async getAllDiscounts() {
      this.loading = true;
      try {
        await this.$store.dispatch('getDiscounts');
        this.discounts = this.getDiscounts;
        /* VERIFICA QUANTAS LINHAS TERÁ A TABELA */
        this.rows = this.discounts.length;
        this.perPage = this.rows;

        for (const disc of this.discounts) {
          const brands = disc.brands;
          const brandObjects = brands.map((brand1) => {
            const found = this.brands.find((brand) => brand.id == brand1);
            return {
              id: found.id,
              name: found.name,
            };
          });
          disc.brands = brandObjects;
        }
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    async getAllBrands() {
      this.loading = true;
      try {
        await this.$store.dispatch('getAllBrands');
        this.brands = this.getBrands;
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    splitDate(date) {
      let split = date.split('T');
      return split[0];
    },
    async createDiscount() {
      const brands = this.new_brand.map((brand) => brand.id);
      try {
        await this.$store.dispatch('addDiscount', {
          site: this.new_site,
          brand: brands,
          start_date: this.new_start_date,
          end_date: this.new_end_date,
          margin: this.new_margin,
          free_shipping: this.new_free_shipping,
          payment_perc: this.new_payment_perc,
          kg_price: this.new_kg_price,
          limit_discount: this.new_limit_discount,
          priority: this.new_priority,
        });
      } catch (err) {
        alert(err);
      }
      await this.getAllDiscounts();
      this.new_site = '';
      this.new_brand = '';
      this.new_start_date = '';
      this.new_end_date = '';
      this.new_margin = '';
      this.new_free_shipping = '';
      this.new_payment_perc = '';
      this.new_kg_price = '';
      this.new_limit_discount = '';
      this.new_priority = '';
      this.$refs['modal_add-disc'].hide();
    },
    async passDiscModal(data) {
      this.id_up = data.id;
      this.start_date_up = this.splitDate(data.start_date);
      this.end_date_up = this.splitDate(data.end_date);
      this.margin_up = data.margin;
      this.free_shipping_up = data.free_shipping;
      this.payment_perc_up = data.payment_perc;
      this.kg_price_up = data.kg_price;
      this.limit_discount_up = data.limit_discount;
      this.priority_up = data.priority;
      this.$refs['modal_update-disc'].show();
    },
    async updateDiscount() {
      try {
        await this.$store.dispatch('updateDiscount', {
          id: this.id_up,
          start_date: this.start_date_up,
          end_date: this.end_date_up,
          margin: this.margin_up,
          free_shipping: this.free_shipping_up,
          payment_perc: this.payment_perc_up,
          kg_price: this.kg_price_up,
          limit_discount: this.limit_discount_up,
          priority: this.priority_up,
        });
      } catch (err) {
        alert(err);
      }
      await this.getAllDiscounts();
      this.id_up = '';
      this.start_date_up = '';
      this.end_date_up = '';
      this.margin_up = '';
      this.free_shipping_up = '';
      this.payment_perc_up = '';
      this.kg_price_up = '';
      this.limit_discount_up = '';
      this.priority_up = '';
      this.$refs['modal_update-disc'].hide();
    },
    async deleteDiscount(data) {
      this.$swal({
        title: 'Quer eliminar o desconto ' + data.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eliminar!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch('deleteDiscount', {
              id: data.id,
            });
            await this.getAllDiscounts();
          } catch (err) {
            alert(err);
          }
          this.$swal('Completado', 'Desconto eliminado!', 'success');
        }
      });
    },
    //Products
    async getAllProducts() {
      try {
        await this.$store.dispatch('fetchProducts');
        this.products = this.getProducts.data;
        console.log('PRODUTOS', this.products);
      } catch (err) {
        alert(err);
      }
    },
    async filterProducts(data) {
      this.id_up = data.id;
      this.products_brands = this.products.filter((product) => {
        return data.brands.some((brand) => brand.id == product.brand_id);
      });
      console.log('PRODUTOS FILTRADOS', this.products_brands);
      this.products_discount = this.products_brands.filter((product) => {
        if (product.discounts == null) return false;
        return product.discounts.some((discount) => discount == data.id);
      });
      console.log('PRODUTOS FILTRADOS', this.products_discount);
      this.list1 = this.products_brands.filter((product) => {
        if (product.discounts == null) return true;
        return !product.discounts.some((discount) => discount == data.id);
      });
      this.list2 = this.products_discount;
      this.list1.sort(function (a, b) {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.list2.sort(function (a, b) {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.$refs['modal_add-prod'].show();
    },
    async updateProducts() {
      try {
        await this.$store.dispatch('setDiscountProducts', {
          id: this.id_up,
          products: this.list2,
        });
      } catch (err) {
        alert(err);
      }
      this.id_up = '';
      this.list1 = [];
      this.list2 = [];
      this.$refs['modal_add-prod'].hide();
      await this.getAllProducts();
    },

    //Modal Actions
    oneToRight() {
      let sele = document.getElementById('list1').value;
      console.log(this.select[0]);
      if (sele != '') {
        this.list2.push(this.select[0]);
        let del = this.list1.indexOf(this.select[0]);
        this.list1.splice(del, 1);
        console.log(this.list2);
      }
    },
    oneToLeft() {
      console.log(this.select2[0]);
      let sele = document.getElementById('list2').value;
      if (sele != '') {
        this.list1.push(this.select2[0]);
        let del = this.list2.indexOf(this.select2[0]);
        this.list2.splice(del, 1);
      }
    },
    allToRight: function () {
      for (let i = 0; i < this.list1.length; i++) {
        this.list2.push(this.list1[i]);
      }
      this.list1 = [];
    },
    allToLeft: function () {
      for (let i = 0; i < this.list2.length; i++) {
        this.list1.push(this.list2[i]);
      }
      this.list2 = [];
    },
  },
  computed: {
    ...mapGetters(['getDiscounts']),
    ...mapGetters(['getBrands']),
    ...mapGetters(['getProducts']),
  },
  async created() {
    /* NA CRIAÇÃO DA PÁGINA CHAMA A FUNÇÃO QUE RETORNA TODOS OS PRODUTOS */
    await this.getAllBrands();
    await this.getAllDiscounts();
    await this.getAllProducts();
  },
};
</script>

<style scoped>
.productslist {
  text-align: center;
}
.table {
  font-size: 12px;
  margin-top: 30px;
}

/* PONTOS ONDE SURGE A COR DO STOCK */
.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: goldenrod;
  border-radius: 50%;
  display: inline-block;
}
.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

/* BARRA DE PESQUISA */
#filter {
  width: 200px;
  margin-right: 0;
}
.searchbar {
  position: absolute;
  right: 0px;
  width: 200px;
  margin: 10px 20px 0px 0px;
}

.customPage {
  background-color: tomato;
}

h2 {
  margin-top: 20px;
}

.waitAddress {
  margin: 30px 5px 0px 5px;
  background-color: #7097b4;
  border-color: #7097b4;
}
.waitAddress:hover {
  background-color: #527d9c;
  border-color: #527d9c;
}

#updateValues {
  margin-left: 5px;
  cursor: pointer;
}
#updateValues:hover {
  cursor: pointer;
}

#list1 {
  overflow-x: auto;
}

#list2 {
  overflow-x: auto;
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}
</style>
